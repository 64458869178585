import 'core-js/stable';
import Vue from 'vue';
import App from './App';
import {router} from './router';
import CoreuiVue from '@coreui/vue';
import {iconsSet as icons} from './assets/icons/icons.js';
import store from './_store';
import DadataSuggestions from 'vue-dadata-suggestions';
import VueNumericInput from 'vue-numeric-input';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueNumericInput)
Vue.prototype.$log = console.log.bind(console)

Vue.use(DadataSuggestions, {
  token: '169462deb3eca8241aac3d61fe1888e157d77abd',
  type: 'ADDRESS'
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})

