import {orderDetailsService} from '../_services';

export const orderDetails = {
  namespaced: true,
  state: {
    byOrder: {}
  },
  actions: {
    getByOrderId ({ commit }, orderId) {
      commit('getByOrderIdRequest', orderId)
      return orderDetailsService.getAll(orderId).then(
        orderDetails =>
          commit('getByOrderIdSuccess', { orderId, orderDetails }),
        error => commit('getByOrderIdFailure', { orderId, error })
      )
    }
  },

  mutations: {
    getByOrderIdRequest (state, orderId) {
      state.byOrder[orderId] = []
    },
    getByOrderIdSuccess (state, payload) {
      state.byOrder[payload.orderId] = payload.orderDetails
    },
    getByOrderIdFailure (state, payload) {
      state.byOrder[payload.orderId] = []
    }
  }
}
