export const ui = {
  namespaced: true,
  state: {
    sidebarVisible: 'responsive',
    sidebarMinimized: false
  },
  actions: {},
  mutations: {
    hideSidebar (state) {
      state.sidebarVisible = false
    },
    showSidebar (state) {
      state.sidebarVisible = 'responsive'
    },
    minimizeSidebar (state) {
      state.sidebarMinimized = true
    },
    maximizeSidebar (state) {
      state.sidebarMinimized = false
    }
  }
}
