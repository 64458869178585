import {router} from '../router/index';
import {dictionaryMountTypesService} from '../_services';

export const dictionaryMountTypes = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      return dictionaryMountTypesService.getAll()
        .then(
          dictionaryMountTypes => commit('getAllSuccess', dictionaryMountTypes),
          error => commit('getAllFailure', error)
        )
    },
    getMeta ({ commit }, file) {},
    getById ({ commit }, id) {
      commit('getByIdRequest')
      dictionaryMountTypesService.getById(id).then(
        dictionaryEntry => commit('getByIdSuccess', dictionaryEntry),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      dictionaryMountTypesService.create(payload).then(
        file => {
          commit('createSuccess', file)
          dispatch('getAll', payload)
          dispatch('getMeta', file)
          router.push('/dictionaries')
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      dictionaryMountTypesService.update(payload).then(
        file => {
          commit('updateSuccess', file)
          dispatch('getAll', payload)
          dispatch('getMeta', file)
          router.push('/dictionaries')
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
  },
  mutations: {
    getAllRequest (state) {
      state.all = []
    },
    getAllSuccess (state, dictionaryMountTypes) {
      state.all = dictionaryMountTypes
    },
    getAllFailure (state, error) {
      state.all = { error }
    },
    getByIdRequest (state, formData) {},
    getByIdSuccess (state, dictionaryEntry) {
      state.all = { dictionaryEntry: dictionaryEntry }
    },
    getByIdFailure (state, error) {},
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, formData) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
  }
}
