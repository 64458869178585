import {equipmentItemsService} from '../_services';

export const equipmentItems = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      return equipmentItemsService.getAll().then(
        equipmentItems => commit('getAllSuccess', equipmentItems),
        error => commit('getAllFailure', error)
      )
    },
    getByCode ({ commit }, code) {
      commit('getByCodeRequest', code)
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      const request = payload
      equipmentItemsService.update(payload).then(
        response => {
          commit('updateSuccess', { request, response })
          dispatch('getAll')
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest (state) {},
    getAllSuccess (state, equipmentItems) {
      state.all = equipmentItems
    },
    getAllFailure (state, error) {
      state.all = []
    },
    getByIdRequest (state) {
      state.activeEquipmentItem = { loading: true }
    },
    getByIdSuccess (state, code) {
      state.all = { items: equipmentItems }
    },
    getByIdFailure (state, error) {
      state.all = { error }
    },
    updateRequest (state, payload) {},
    updateSuccess (state, payload) {},
    updateFailure (state, error) {},
  }
}
