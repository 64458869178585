import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from './alert.module'
import { account } from './account.module'
import { orders } from './orders.module'
import { orderItems } from './order-items.module'
import { orderPlatforms } from './order-platforms.module'
import { orderDetails } from './order-details.module'
import { equipmentItems } from './equipment-items.module'
import { equipmentParts } from './equipment-parts.module'
import { equipmentDetails } from './equipment-details.module'
import { equipmentPackages } from './equipment-packages.module'
import { spareParts } from './spare-parts.module'
import { dictionaryColors } from './dictionary-colors.module'
import { dictionaryCoverings } from './dictionary-coverings.module'
import { dictionaryFoundations } from './dictionary-foundations.module'
import { dictionaryMountTypes } from './dictionary-mount-types.module'
import { dictionarySputterings } from './dictionary-sputterings.module'
import { standardProjects } from './standard-projects.module'
import { ui } from './ui.module'
import { users } from './users.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert,
    account,
    orders,
    orderItems,
    orderPlatforms,
    orderDetails,
    equipmentItems,
    equipmentParts,
    equipmentDetails,
    equipmentPackages,
    spareParts,
    dictionaryColors,
    dictionaryCoverings,
    dictionaryFoundations,
    dictionaryMountTypes,
    dictionarySputterings,
    standardProjects,
    ui,
    users,
  }
})
