import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'
import config from 'config'

class OrderDetailsService extends ApiObjectService {
  getAll (orderId) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-details/`,
      requestOptions
    ).then(this.handleResponse)
  }
}

export const orderDetailsService = new OrderDetailsService()
