import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'
import config from 'config'

class EquipmentPartsService extends ApiObjectService {
    namespace = 'equipment-parts'
}

export const equipmentPartsService = new EquipmentPartsService()

equipmentPartsService.getByEquipmentItemId = function(equipmentItemId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl ?? ''}/api/equipment-parts-per-equipment-item/${equipmentItemId}/`,
    requestOptions
  ).then(this.handleResponse)
}

equipmentPartsService.createEquipmentDetailEquipmentPart = function(formData) {
  console.log('formDataService  '+ (JSON.stringify(formData, null, 4)))

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(formData)
  }

  return fetch(
    `${config.apiUrl ?? ''}/api/equipment-detail-equipment-part-create/`,
    requestOptions
  ).then(this.handleResponse)
}

equipmentPartsService.updateEquipmentDetailEquipmentPart = function(formData) {

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(formData)
  }

  return fetch(
    `${config.apiUrl ?? ''}/api/equipment-detail-equipment-part-update/`,
    requestOptions
  ).then(this.handleResponse)
}

equipmentPartsService.deleteEquipmentDetailEquipmentPart = function(formData) {

  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(formData)
  }

  return fetch(
    `${config.apiUrl ?? ''}/api/equipment-detail-equipment-part-delete/`,
    requestOptions
  ).then(this.handleResponse)
}
