import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'

class StandardProjectsService extends ApiObjectService {
    namespace = 'standard-projects'

    saveFile (id, dataUrl, fileType) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ data_url: dataUrl, file_type: fileType })
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/${this.namespace}/${id}/save-file/`,
        requestOptions
      ).then(this.handleResponse)
    }

    getEquipmentItems (id) {
      const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/${this.namespace}/${id}/equipment-items/`,
        requestOptions
      ).then(this.handleResponse)
    }

    addEquipmentItem (id, equipmentItemId) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ equipment_item_id: equipmentItemId })
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/${this.namespace}/${id}/add-equipment-item/`,
        requestOptions
      ).then(this.handleResponse)
    }

    removeEquipmentItem (id, equipmentItemKey) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ equipment_item_key: equipmentItemKey })
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/${this.namespace}/${id}/remove-equipment-item/`,
        requestOptions
      ).then(this.handleResponse)
    }
}

export const standardProjectsService = new StandardProjectsService()
