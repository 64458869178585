import { equipmentPackagesService } from '../_services'

export const equipmentPackages = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      return equipmentPackagesService.getAll().then(
        equipmentPackages => commit('getAllSuccess', equipmentPackages),
        error => commit('getAllFailure', error)
      )
    },
    getByCode ({ commit }, code) {
      commit('getByIdRequest', code)
    },
    getByEquipmentItemId ({ commit }, code) {
      commit('getByEquipmentItemIdRequest', code)
      return equipmentPackagesService.getByEquipmentItemId(code).then(
        equipmentPackages =>
          commit('getByEquipmentItemIdSuccess', equipmentPackages),
        error => commit('getByEquipmentItemIdFailure', error)
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      const request = payload
      equipmentPackagesService.update(payload).then(
        response => {
          commit('updateSuccess', { request, response })
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest (state) {},
    getAllSuccess (state, equipmentPackages) {
      state.all = equipmentPackages
    },
    getAllFailure (state, error) {
      state.all = []
    },
    getByIdRequest (state) {
      state.activeEquipmentPart = { loading: true }
    },
    getByIdSuccess (state, code) {},
    getByEquipmentItemIdRequest (state, payload) {},
    getByEquipmentItemIdSuccess (state, payload) {},
    getByEquipmentItemIdFailure (state, payload) {},
    updateRequest (state, payload) {},
    updateSuccess (state, payload) {},
    updateFailure (state, error) {}
  }
}
