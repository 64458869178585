import {router} from '../router/index';
import {userService} from '../_services';

export const users = {
  namespaced: true,
  state: {
    all: {},
    filtered: {}
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      userService.getAll().then(
        users => commit('getAllSuccess', users),
        error => commit('getAllFailure', error)
      )
    },
    getFiltered ({ commit }, filter) {
      commit('getFilteredRequest')
      userService.getFiltered(filter).then(
        users => commit('getFilteredSuccess', users),
        error => commit('getFilteredFailure', error)
      )
    },
    getCurrent ({ commit }) {
      commit('getCurrentRequest')
      return userService.getCurrent().then(
        users => commit('getCurrentSuccess', users),
        error => commit('getCurrentFailure', error)
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      userService.getById(id).then(
        user => commit('getByIdSuccess', user),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, formData) {
      commit('createRequest', formData)
      userService.create(formData).then(
        file => {
          commit('createSuccess', file)
          dispatch('getAll')
          router.push('/users')
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, data) {
      commit('updateRequest', data)
      userService.update(data).then(
        file => {
          commit('updateSuccess', file)
          dispatch('getAll', data)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest (state) {
      state.all = { loading: true }
    },
    getAllSuccess (state, users) {
      state.all = { items: users }
    },
    getAllFailure (state, error) {
      state.all = { error }
    },
    getCurrentRequest (state) {
      state.all = { loading: true }
    },
    getCurrentSuccess (state, users) {
      state.all = { items: users }
    },
    getCurrentFailure (state, error) {
      state.all = { error }
    },
    getByIdRequest (state, formData) {},
    getByIdSuccess (state, user) {
      state.all = { user: user }
    },
    getByIdFailure (state, error) {},
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, data) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
    getFilteredRequest (state, data) {
      state.filtered = { loading: true }
    },
    getFilteredSuccess (state, users) {
      state.filtered = { items: users }
    },
    getFilteredFailure (state, error) {
      state.filtered = { loading: true }
    }
  }
}
