export function createStoreModule (service) {
  return {
    namespaced: true,
    state: {
      all: []
    },
    actions: {
      getAll ({ commit }) {
        commit('getAllRequest')
        return service.getAll().then(
          dataObjects =>
            commit('getAllSuccess', dataObjects),
          error => commit('getAllFailure', error)
        )
      },
      getById ({ commit }, id) {
        commit('getByIdRequest')
        service.getById(id).then(
          dataObject => commit('getByIdSuccess', dataObject),
          error => commit('getByIdFailure', error)
        )
      },
      create ({ dispatch, commit }, payload) {
        commit('createRequest', payload)
        service.create(payload).then(
          dataObject => {
            commit('createSuccess', dataObject)
          },
          error => {
            commit('createFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
      },
      update ({ dispatch, commit }, payload) {
        commit('updateRequest', payload)
        service.update(payload).then(
          dataObject => {
            commit('updateSuccess', dataObject)
          },
          error => {
            commit('updateFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
      }
    },
    mutations: {
      getAllRequest (state) {},
      getAllSuccess (state, dataObjects) {
        state.all = dataObjects
      },
      getAllFailure (state, error) {},
      getByIdRequest (state, payload) {},
      getByIdSuccess (state, dataObject) {
        state.all[parseInt(dataObject.id)] = dataObject
      },
      getByIdFailure (state, error) {},
      createRequest (state, payload) {},
      createSuccess (state, file) {},
      createFailure (state, error) {},
      updateRequest (state, payload) {},
      updateSuccess (state, file) {},
      updateFailure (state, error) {}
    }
  }
}
