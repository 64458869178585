import { standardProjectsService } from '../_services'

export const standardProjects = {
  namespaced: true,
  state: {
    all: [],
    equipmentItems: {}
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      standardProjectsService.getAll().then(
        standardProjects => commit('getAllSuccess', standardProjects),
        error => commit('getAllFailure', error)
      )
    },

    getByCode ({ commit }, code) {
      commit('getByIdRequest', code)
    },

    getEquipmentItems ({ dispatch, commit }, payload) {
      commit('getEquipmentItemsRequest', payload)
      standardProjectsService.getEquipmentItems(payload.id).then(
        payload => {
          commit('getEquipmentItemsSuccess', payload)
        },
        error => {
          commit('getEquipmentItemsFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },

    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      standardProjectsService.create(payload).then(
        standardProject => {
          commit('createSuccess', standardProject)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },

    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      const request = payload
      standardProjectsService.update(payload).then(
        response => {
          commit('updateSuccess', { request, response })
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },

    saveFile ({ dispatch, commit }, payload) {
      commit('saveFileRequest', payload)
      standardProjectsService
        .saveFile(payload.id, payload.dataUrl, payload.fileType)
        .then(
          payload => {
            commit('saveFileSuccess', payload)
          },
          error => {
            commit('saveFileFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
    },

    addEquipmentItem ({ dispatch, commit }, payload) {
      commit('addEquipmentItemRequest', payload)
      standardProjectsService
        .addEquipmentItem(payload.standardProjectId, payload.equipmentItemId)
        .then(
          payload => {
            commit('addEquipmentItemSuccess', payload)
          },
          error => {
            commit('addEquipmentItemFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
    },

    removeEquipmentItem ({ dispatch, commit }, payload) {
      commit('removeEquipmentItemRequest', payload)
      standardProjectsService
        .removeEquipmentItem(payload.standardProjectId, payload.equipmentItemKey)
        .then(
          payload => {
            commit('removeEquipmentItemSuccess', payload)
          },
          error => {
            commit('removeEquipmentItemFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
    }
  },
  mutations: {
    getAllRequest (state) {},
    getAllSuccess (state, standardProjects) {
      state.all = standardProjects
    },
    getAllFailure (state, error) {
      state.all = []
    },

    getEquipmentItemsRequest (state) {},
    getEquipmentItemsSuccess (state, payload) {
      state.equipmentItems[payload.id] = payload.equipment_items
    },
    getEquipmentItemsFailure (state, error) {
      state.all = []
    },
    getByIdRequest (state) {},
    getByIdSuccess (state, code) {},
    createRequest (state, payload) {},
    createSuccess (state, payload) {},
    createFailure (state, error) {},
    updateRequest (state, payload) {},
    updateSuccess (state, payload) {},
    updateFailure (state, error) {},
    saveFileRequest (state, payload) {},
    saveFileSuccess (state, payload) {},
    saveFileFailure (state, error) {},
    addEquipmentItemRequest (state, payload) {},
    addEquipmentItemSuccess (state, payload) {},
    addEquipmentItemFailure (state, error) {},
    removeEquipmentItemRequest (state, payload) {},
    removeEquipmentItemSuccess (state, payload) {},
    removeEquipmentItemFailure (state, error) {}
  }
}
