import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'
import config from 'config'

class EquipmentPackagesService extends ApiObjectService {
  namespace = 'equipment-packages'
}

export const equipmentPackagesService = new EquipmentPackagesService()

equipmentPackagesService.getByEquipmentItemId = function(equipmentItemId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl ?? ''}/api/equipment-packages-per-item/${equipmentItemId}/`,
    requestOptions
  ).then(this.handleResponse)
}
