import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'
import config from 'config'

class EquipmentDetailsService extends ApiObjectService {
    namespace = 'equipment-details'
}

export const equipmentDetailsService = new EquipmentDetailsService()

equipmentDetailsService.getByEquipmentPartId = function(equipmentPartId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl ?? ''}/api/equipment-details-per-equipment-part/${equipmentPartId}/`,
    requestOptions
  ).then(this.handleResponse)
}

equipmentDetailsService.updateEquipmentDetailEquipmentPackage = function(formData) {

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(formData)
  }

  return fetch(
    `${config.apiUrl ?? ''}/api/equipment-package-equipment-detail-update/`,
    requestOptions
  ).then(this.handleResponse)
}
