import {orderPlatformsService} from '../_services';

export const orderPlatforms = {
  namespaced: true,
  state: {
    byOrder: {}
  },
  actions: {
    getByOrderId ({ commit }, orderId) {
      commit('getByOrderIdRequest', orderId)
      return orderPlatformsService.getAll(orderId).then(
        orderPlatforms =>
          commit('getByOrderIdSuccess', { orderId, orderPlatforms }),
        error => commit('getByOrderIdFailure', { orderId, error })
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      const orderId = payload.orderId
      const request = payload
      orderPlatformsService.create(payload.orderId, payload.orderPlatform).then(
        response => {
          commit('createSuccess', { request, response })
          dispatch('getByOrderId', orderId)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      const orderId = payload.orderId
      const request = payload
      orderPlatformsService.update(payload.orderId, payload.orderPlatform).then(
        response => {
          commit('updateSuccess', { request, response })
          dispatch('getByOrderId', orderId)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    delete ({ dispatch, commit }, orderId, orderPlatformId) {
      commit('deleteRequest', { orderId, orderPlatformId })
      orderPlatformsService.delete(orderId, orderPlatformId).then(
        payload => {
          commit('deleteSuccess', { orderId, orderPlatformId })
          dispatch('getByOrderId', orderId)
        },
        error => {
          commit('deleteFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getByOrderIdRequest (state, orderId) {
      state.byOrder[orderId] = []
    },
    getByOrderIdSuccess (state, payload) {
      state.byOrder[payload.orderId] = payload.orderPlatforms
    },
    getByOrderIdFailure (state, payload) {
      state.byOrder[payload.orderId] = []
    },
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, formData) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
    deleteRequest (state, formData) {},
    deleteSuccess (state, file) {},
    deleteFailure (state, error) {}
  }
}
