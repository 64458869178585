import { sparePartsService } from '../_services'

export const spareParts = {
  namespaced: true,
  state: {
    all: {},
    activeSparePart: {}
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      sparePartsService.getAll()
        .then(
          spareParts => commit('getAllSuccess', spareParts),
          error => commit('getAllFailure', error)
        )
    },
    getByCode({ commit }, code) {
        commit('getByIdRequest', code)
    }
  },
  mutations: {
    getAllRequest (state) {
      state.all = { loading: true }
    },
    getAllSuccess (state, spareParts) {
      state.all = { parts: spareParts }
    },
    getAllFailure (state, error) {
      state.all = { error }
    },
    getByIdRequest (state) {
      state.activeSparePart = { loading: true }
    },
    getByIdSuccess (state, code) {
      state.all = { parts: spareParts }
    },
    getAllFailure (state, error) {
      state.all = { error }
    },
  }
}
