import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'


class UserService extends ApiObjectService {
    namespace = 'users'

    getCurrent () {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }
  
      return fetch(
        `${config.apiUrl}/api/${this.namespace}/current/`,
        requestOptions
      ).then(this.handleResponse)
    }

    getFiltered (filter) {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }
      const params = new URLSearchParams(filter);
      return fetch(
        `${config.apiUrl}/api/${this.namespace}/?${params.toString()}`,
        requestOptions
      ).then(this.handleResponse)
    }
}

export const userService = new UserService()
