import config from 'config'
import { authHeader } from '../_helpers'
import { authService } from './auth.service'

export class CRMCompaniesService {
  query (query) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }
    const url = new URL(`${config.apiUrl}/api/crm/companies/`)
    url.search = new URLSearchParams({ query }).toString()

    return fetch(url, requestOptions).then(this.handleResponse)
  }

  handleResponse (response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text)
      if (response.status === 401) {
        authService.logout()
        location.reload(true)
      }

      return data
    })
  }
}

export const crmCompaniesService = new CRMCompaniesService()
