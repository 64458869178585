import { equipmentDetailsService } from '../_services'

export const equipmentDetails = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      return equipmentDetailsService.getAll().then(
        equipmentDetails => commit('getAllSuccess', equipmentDetails),
        error => commit('getAllFailure', error)
      )
    },
    getByCode ({ commit }, code) {
      commit('getByIdRequest', code)
    },
    getByEquipmentPartId ({ commit }, code) {
      commit('getByEquipmentPartIdRequest', code)
      return equipmentDetailsService.getByEquipmentPartId(code).then(
        equipmentParts => commit('getByEquipmentPartIdSuccess', equipmentParts),
        error => commit('getByEquipmentPartIdFailure', error)
      )
    },
    updateEquipmentDetailEquipmentPackage ({ commit }, payload) {
      commit('updateEquimentDetailEquipmentPackageRequest', payload)
      return equipmentDetailsService.updateEquipmentDetailEquipmentPackage(payload).then(
        result => commit('updateEquimentDetailEquipmentPackageSuccess', result),
        error => commit('updateEquimentDetailEquipmentPackageFailure', error)
      )
    },

    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      const request = payload
      equipmentDetailsService.update(payload).then(
        response => {
          commit('updateSuccess', { request, response })
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      const request = payload
      equipmentDetailsService.create(payload).then(
        response => {
          commit('createSuccess', { request, response })
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest () {},
    getAllSuccess (state, equipmentDetails) {
      state.all = equipmentDetails
    },
    getAllFailure (state) {
      state.all = []
    },
    getByIdRequest (state) {
      state.activeEquipmentPart = { loading: true }
    },
    getByIdSuccess () {},
    getByEquipmentPartIdRequest () {},
    getByEquipmentPartIdSuccess () {},
    getByEquipmentPartIdFailure () {},
    updateEquimentDetailEquipmentPackageRequest () {},
    updateEquimentDetailEquipmentPackageSuccess () {},
    updateEquimentDetailEquipmentPackageFailure () {},
    updateRequest () {},
    updateSuccess () {},
    updateFailure () {},
    createRequest () {},
    createSuccess () {},
    createFailure () {}
  }
}
