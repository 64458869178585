import { orderItemsService } from '../_services'

export const orderItems = {
  namespaced: true,
  state: {
    byOrder: {}
  },
  actions: {
    getByOrderId ({ commit }, orderId) {
      commit('getByOrderIdRequest', { orderId })
      return orderItemsService.getAll(orderId).then(
        orderItems => commit('getByOrderIdSuccess', { orderId, orderItems }),
        error => commit('getByOrderIdFailure', { orderId, error })
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      const orderId = payload.orderId
      const request = payload
      orderItemsService.create(payload.orderId, payload.orderItem).then(
        response => {
          commit('createSuccess', { request, response })
          dispatch('getByOrderId', orderId)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      const request = payload
      orderItemsService.update(payload.orderId, payload.orderItem).then(
        response => {
          commit('updateSuccess', { request, response })
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    addOrUpdatePart ({ dispatch, commit }, payload) {
      commit('addOrUpdatePartRequest', payload)
      const request = payload
      orderItemsService.addOrUpdatePart(payload.orderItemId, payload.orderItemPartKey, payload.orderItemPart).then(
        response => {
          commit('addOrUpdatePartSuccess', { request, response })
        },
        error => {
          commit('addOrUpdatePartFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    deletePart ({ dispatch, commit }, payload) {
      commit('deletePartRequest', payload)
      const request = payload
      orderItemsService.deletePart(payload.orderItemId, payload.orderItemPartKey).then(
        response => {
          commit('deletePartSuccess', { request, response })
        },
        error => {
          commit('deletePartFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    delete ({ dispatch, commit }, payload) {
      commit('deleteRequest', payload)
      const orderId = payload.orderId
      return orderItemsService
        .delete(payload.orderId, payload.orderItemId)
        .then(
          payload => {
            commit('deleteSuccess', payload)
            dispatch('getByOrderId', orderId)
          },
          error => {
            commit('deleteFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
    },
    saveRender ({ dispatch, commit }, payload) {
      commit('saveRenderRequest', payload)
      const orderId = payload.orderId
      orderItemsService.saveRender(payload.orderItemId, payload.dataUrl).then(
        payload => {
          commit('saveRenderSuccess', payload)
          dispatch(
            'orders/getByIdWithParameters',
            {
              id: orderId,
              loadFromStorage: true
            },
            { root: true }
          )
        },
        error => {
          commit('saveRenderFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },

  mutations: {
    getByOrderIdRequest (state, payload) {
      if (!state.byOrder[payload.orderId]) {
        state.byOrder[payload.orderId] = []
      }
    },
    getByOrderIdSuccess (state, payload) {
      for (const newOrderItem of payload.orderItems) {
        let orderItemExists = false
        for (const existingOrderItem of state.byOrder[payload.orderId]) {
          if (existingOrderItem.id === newOrderItem.id) {
            existingOrderItem.equipment_price = newOrderItem.equipment_price
            orderItemExists = true
            break
          }
        }
        if (!orderItemExists) {
          state.byOrder[payload.orderId].push(newOrderItem)
        }
      }
      const orderItemIndexesToDelete = []
      for (const existingOrderItemIndex in state.byOrder[payload.orderId]) {
        let orderItemExists = false
        const existingOrderItem =
          state.byOrder[payload.orderId][existingOrderItemIndex]
        for (const newOrderItem of payload.orderItems) {
          if (existingOrderItem.id === newOrderItem.id) {
            orderItemExists = true
            break
          }
        }
        if (!orderItemExists) {
          orderItemIndexesToDelete.push(existingOrderItemIndex)
        }
      }
      for (const orderItemIndexToDelete of orderItemIndexesToDelete) {
        state.byOrder[payload.orderId].splice(orderItemIndexToDelete, 1)
      }
    },

    getByOrderIdFailure (state, payload) {
      if (!state.byOrder[payload.orderId]) {
        state.byOrder[payload.orderId] = []
      }
    },
    createRequest (state, orderId, item) {},
    createSuccess (state, orderId, item) {},
    createFailure (state, error) {},
    updateRequest (state, orderId, item) {},
    updateSuccess (state, orderId, item) {},
    updateFailure (state, error) {},
    addOrUpdatePartRequest (state, orderItemId, orderItemPartKey, itemPart) {},
    addOrUpdatePartSuccess (state, orderItemId, orderItemPartKey, itemPart) {},
    deletePartRequest (state, orderItemId, orderItemPartKey) {},
    deletePartSuccess (state, orderItemId, orderItemPartKey) {},
    deletePartFailure (state, error) {},
    deleteRequest (state, orderId, itemId) {},
    deleteSuccess (state, orderId, itemId) {},
    deleteFailure (state, error) {},
    saveRenderRequest (state) {},
    saveRenderSuccess (state, payload) {},
    saveRenderFailure (state, error) {}
  }
}
