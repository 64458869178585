import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'
import config from 'config'

class OrderPlatformsService extends ApiObjectService {
  create (orderId, data) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-platforms/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getAll (orderId) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-platforms/`,
      requestOptions
    ).then(this.handleResponse)
  }

  update (orderId, data) {
    const requestOptions = {
      method: 'PUT',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-platforms/${data.id}/`,
      requestOptions
    ).then(this.handleResponse)
  }

  delete (orderId, id) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-platforms/${id}`,
      requestOptions
    ).then(this.handleResponse)
  }
}

export const orderPlatformsService = new OrderPlatformsService()
