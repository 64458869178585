import { getCurrentUser } from "../_helpers"
export function authHeader () {
  // return authorization header with jwt token
  const user = getCurrentUser()

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token }
  } else {
    return {}
  }
}
