import { ApiObjectService } from './api-object.service'
import { authHeader } from '../_helpers'
import config from 'config'

class OrderItemsService extends ApiObjectService {
  create (orderId, data) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-items/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getAll (orderId) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-items/`,
      requestOptions
    ).then(this.handleResponse)
  }

  update (orderId, data) {
    const newData = Object.assign({}, data)
    delete newData.meta
    delete newData.parts
    const requestOptions = {
      method: 'PATCH',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(newData)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-items/${data.id}/`,
      requestOptions
    ).then(this.handleResponse)
  }

  delete (orderId, id) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders/${orderId}/order-items/${id}/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getParts (orderItemId) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders-items/${orderItemId}/parts/`,
      requestOptions
    ).then(this.handleResponse)
  }

  addOrUpdatePart (orderItemId, orderItemPartKey, data) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders-items/${orderItemId}/parts/${orderItemPartKey}/`,
      requestOptions
    ).then(this.handleResponse)
  }

  deletePart (orderItemId, orderItemPartKey) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/orders-items/${orderItemId}/parts/${orderItemPartKey}/`,
      requestOptions
    ).then(this.handleResponse)
  }

  saveRender (orderItemId, dataUrl) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ data_url: dataUrl })
    }

    return fetch(
      `${config.apiUrl}/api/orders-items/${orderItemId}/save-render/`,
      requestOptions
    ).then(this.handleResponse)
  }

}

export const orderItemsService = new OrderItemsService()
