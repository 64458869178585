import {router} from '../router/index';
import {dictionarySputteringsService} from '../_services';

export const dictionarySputterings = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      return dictionarySputteringsService.getAll().then(
        dictionarySputterings => commit('getAllSuccess', dictionarySputterings),
        error => commit('getAllFailure', error)
      )
    },
    getMeta ({ commit }, file) {},
    getById ({ commit }, id) {
      commit('getByIdRequest')
      dictionarySputteringsService.getById(id).then(
        dictionaryEntry => commit('getByIdSuccess', dictionaryEntry),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      dictionarySputteringsService.create(payload).then(
        file => {
          commit('createSuccess', file)
          dispatch('getAll', payload)
          dispatch('getMeta', file)
          router.push('/dictionaries')
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      dictionarySputteringsService.update(payload).then(
        file => {
          commit('updateSuccess', file)
          dispatch('getAll', payload)
          dispatch('getMeta', file)
          router.push('/dictionaries')
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest (state) {
      state.all = []
    },
    getAllSuccess (state, dictionarySputterings) {
      state.all = dictionarySputterings
    },
    getAllFailure (state, error) {
      state.all = { error }
    },
    getByIdRequest (state, formData) {},
    getByIdSuccess (state, dictionaryEntry) {
      state.all = { dictionaryEntry: dictionaryEntry }
    },
    getByIdFailure (state, error) {},
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, formData) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {}
  }
}
