import { equipmentPartsService } from '../_services'

export const equipmentParts = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      return equipmentPartsService.getAll().then(
        equipmentParts => commit('getAllSuccess', equipmentParts),
        error => commit('getAllFailure', error)
      )
    },
    getByCode ({ commit }, code) {
      commit('getByIdRequest', code)
    },
    getByEquipmentItemId ({ commit }, code) {
      commit('getByEquipmentItemIdRequest', code)
      return equipmentPartsService.getByEquipmentItemId(code).then(
        equipmentParts => commit('getByEquipmentItemIdSuccess', equipmentParts),
        error => commit('getByEquipmentItemIdFailure', error)
      )
    },
    createEquipmentDetailEquipmentPart ({ commit }, payload) {
      console.log('payload '+ (JSON.stringify(payload, null, 4)))
      commit('createEquipmentDetailEquipmentPartRequest', payload)
      return equipmentPartsService.createEquipmentDetailEquipmentPart(payload).then(
        result => commit('createEquipmentDetailEquipmentPartSuccess', result),
        error => commit('createEquipmentDetailEquipmentPartFailure', error)
      )
    },
    updateEquipmentDetailEquipmentPart ({ commit }, payload) {
      commit('updateEquipmentDetailEquipmentPartRequest', payload)
      return equipmentPartsService.updateEquipmentDetailEquipmentPart(payload).then(
        result => commit('updateEquipmentDetailEquipmentPartSuccess', result),
        error => commit('updateEquipmentDetailEquipmentPartFailure', error)
      )
    },
    deleteEquipmentDetailEquipmentPart ({ commit }, payload) {
      commit('deleteEquipmentDetailEquipmentPartRequest', payload)
      return equipmentPartsService.deleteEquipmentDetailEquipmentPart(payload).then(
        result => commit('deleteEquipmentDetailEquipmentPartSuccess', result),
        error => commit('deleteEquipmentDetailEquipmentPartFailure', error)
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      const request = payload
      equipmentPartsService.update(payload).then(
        response => {
          commit('updateSuccess', { request, response })
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest () {},
    getAllSuccess (state, equipmentParts) {
      state.all = equipmentParts
    },
    getAllFailure (state) {
      state.all = []
    },
    getByIdRequest (state) {
      state.activeEquipmentPart = { loading: true }
    },
    getByIdSuccess () {},
    getByEquipmentItemIdRequest () {},
    getByEquipmentItemIdSuccess () {},
    getByEquipmentItemIdFailure () {},
    createEquipmentDetailEquipmentPartRequest () {},
    createEquipmentDetailEquipmentPartSuccess () {},
    createEquipmentDetailEquipmentPartFailure () {},
    updateEquipmentDetailEquipmentPartRequest () {},
    updateEquipmentDetailEquipmentPartSuccess () {},
    updateEquipmentDetailEquipmentPartFailure () {},
    deleteEquipmentDetailEquipmentPartRequest () {},
    deleteEquipmentDetailEquipmentPartSuccess () {},
    deleteEquipmentDetailEquipmentPartFailure () {},
    updateRequest () {},
    updateSuccess () {},
    updateFailure () {}
  }
}
